import * as React from "react"
import { Layout } from "../../components/layout"
import { Helmet } from 'react-helmet'

const BlackboxWikiPage = () => {
		return (
		<Layout>
			<Helmet defer={false}>
				<title>Contact Ian Knowles</title>
				<meta name="description" content="Contact Ian Knowles" />
			</Helmet>
			<div className="container">
        <div className="grid-cl-mr">
          <div className="section">
            <ul>
                <li><a href="#introduction">Introduction</a></li>
                <li><a href="#quick-start">Quick Start</a></li>
                <li><a href="#keyboard-shortcuts">Keyboard Shortcuts</a></li>
                <li>
                    <a href="#controls">Controls</a>
                    <ul>
                        <li><a href="#lfc">Low Frequency Cut</a></li>
                        <li><a href="#sub-enhance">Sub Enhance</a></li>
                        <li><a href="#body-level">Body Level</a></li>
                        <li><a href="#clarity">Clarity</a></li>
                        <li><a href="#brightness">Brightness</a></li>
                        <li><a href="#loudness">Loudness</a></li>
                        <li><a href="#compression">Compression</a></li>
                        <li><a href="#quality">Quality</a></li>
                        <li><a href="#other-controls">Other Controls</a></li>
                    </ul>
                </li>

                <li>
                    <a href="#metering">Metering</a>
                    <ul>
                        <li><a href="#fft-metering">FFT Metering</a></li>
                        <li><a href="#loudness-metering">Loudness Metering</a></li>
                    </ul>
                </li>
                <li><a href="#exporting">Exporting</a></li>
            </ul>
          </div>
          <div>
            <section id="introduction">
                <h1>Blackbox Mastering Wiki</h1>
                <p>
                    Blackbox is an analog style mastering chain with sophisticated automation to give clarity, loudness, warmth and punch to music.
                    Blackbox uses expressive controls which allow the user to make a suggestion on how they want their audio to sound rather than having to know the intricacies of an audio mastering chain.
                    Blackbox DSP modules simulate mastering hardware and its internal devices loosely emulate classic mastering gear like the MLA-3 and MBP II.
                    The combining these expressive controls with content aware AI, Blackbox is able to finely tune its mastering chain to the audio content to get the most out of the sound.
                </p>
                <p>
                    <a href="/blackbox" className="btn btn-primary">DOWNLOAD BLACKBOX</a>
                </p>
            </section>

            <section id="quick-start">
                <h3>Quick Start</h3>

                <p><strong>Windows</strong> - download the zip file and extract the binary executable. Put the executable file where you desire and create a shortcut to it if you wish. The Blackbox.exe is standalone so should just run from any location.</p>
                <p><strong>Mac</strong> - download the dmg file and extract the binary application. Put the application in the applications folder on your mac. Run the application. If you get a security warning saying it can't be run as settings only allow applications from Mac App Store, don't worry. Go to Apple -> System Preferences -> Security &amp; Privacy and you should see a notification about &quot;BlackBox was blocked&quot;, click on &quot;Open Anyway&quot; which should allow the program to run!</p>

                <p>
                    Once setup you can drag and drop audio directly to the interface or use the browse icon in the top right hand corner.
                    After selecting the audio file you wish to process you can either process a small section of the track by highlighting and clicking process or you can process the entire track.
                    There are some presets available to select from to aid you getting the desired sound.
                </p>
            </section>

            <section id="keyboard-shortcuts">
                <h3>Keyboard Shortcuts</h3>

                <p>There are several keyboard shortcuts in Blackbox which include:</p>
                <ul>
                    <li><strong>L</strong> - selects / highlights the area that has already been processed for quick listening</li>
                    <li><strong>UP</strong> - sets playback to source audio to assist A and B switching</li>
                    <li><strong>DOWN</strong> - sets playback to target audio to assist A and B switching</li>
                    <li><strong>P</strong> - try and process either the selected audio or the entire track</li>
                    <li><strong>S</strong> - save the processed audio</li>
                </ul>

            </section>

            <section id="controls">
                <h3>Controls</h3>

                <img src="/content/blackbox/II/blackbox-controls.png" className="full-width" alt="Blackbox Controls" />

                <p>
                    Blackbox box uses 8 crafted expressive controls to achieve desired outcomes.
                    It's useful to know the scope of each of these controls and how they affect the signal.
                </p>
                <hr id="lfc" />
                <h4>Low Frequency Cut</h4>
                <p>
                    The LFC, low frequency cut is a digital brickwall style filter that happens early on in the mastering chain.
                    It is responsible for clearing out the rumble within a mix and gives a cleaner signal to the rest of the chain.
                    Be careful not to go too high depending on your audio source as some fundemental frequencies often reside as low as 50hz.
                    As a general rule you want to clean between 28hz and 44hz (this is slightly subjective).
                </p>

                <hr id="sub-enhance" />
                <h4>Sub Enhance</h4>
                <p>
                    The sub enhancer control affects several elements of the sub bass.
                    It is primeraly a gain control but it also serves as a multiplier for bass part of the multiband compressor and as a tuner control for the sub decimator which focuses the fundemental bass frequencies.
                </p>

                <hr id="body-level" />
                <h4>Body Level</h4>
                <p>
                    The body level plays a similair role to the sub enhancer and focuses on the first set of harmonics.
                    This is a useful control and in general should be used in the opposite direction as the sub enhancer.
                    If you want a focused sub bass for loud club speakers then it is recommended that you lower the body level to create more room for sub frequencies.
                    If however you are mixing for CD, Radio and smaller playback devices then it is recommended turning this control up whilst lowering the sub so the bass is more prominent.
                </p>

                <hr id="clarity" />
                <h4>Clarity</h4>
                <p>
                    The clarity control is dictated by the content of the audio.
                    If there is a singer or and instrument like a guitar or maybe some plucky synths that are low in the mix and you want to bring them out then you can bring the clarity up a little.
                    If they are to prominent and you want to push them back or maybe bring out some more stringy or brassy sounds then you may want to turn the control down a little.
                </p>

                <hr id="brightness" />
                <h4>Brightness</h4>
                <p>
                    The brightness control does what it says on the tin and brightens the whole mix.
                    The upper frequencies will get pushed into the saturator and tamed a little by a compressor so its often required to bring the brightness down a little if a track has been produced digitally.
                    If on the other hand you want the mix to have a more crispy air frequency you can push the control quite hard as it maintains perfect phase and shouldnt cause any more siblance than what is already present in the mixdown.
                    This control can also be use creatively as it acts a little bit like a tuned shelf eq, turning it quite far down will give a warm vintage feel to the mix.
                </p>

                <hr id="loudness" />
                <h4>Loudness</h4>
                <p>
                    This is how much the sound is driven into the DSPs within blackbox.
                    It is important to understand the loudness clarity trade off when you really push this setting however, Blackbox has some nifty algorythms for maintaining transients even when pushed hard. So if you like it loud and crunchy then crank the loudness setting up.
                </p>

                <hr id="compression" />
                <h4>Compression</h4>
                <p>
                    There are many tuned compressors in Blackbox and it is one of the reasons why the mixdowns are well balanced.
                    The compressor control will lerp between a range of subtle settings to give more or less glue to the mixdown.
                    The compressors settings should all auto adjust to compensate for the different compression ratios and keep the overall presence the same whilst creating room for elements to shine.
                </p>

                <hr id="quality" />
                <h4>Quality</h4>
                <p>
                    Quality is directly related to the quality of the filters used by blackbox and the time it takes to render audio.
                    Quality ranges from Fast, Low, Medium, High and Best. For context it takes approximately 16 times longer to render audio on the highest setting.
                    It is recommended that you test with medium and then set it to high to render a 44.1 khz track.
                    If you plan on using higher sample rates e.g. 96khz then it is highly recommended you use the Best setting as the low end may suffer on lower quality settings at these sample rates.
                </p>

                <hr id="other-controls" />
                <h3>Other Controls</h3>
                <p>
                    There are several other controls in Blackbox that help fine tune the output. These include
                    <ul>
                        <li>Ceiling Control</li>
                        <li>Loudness Release</li>
                        <li>Stereo Cut Frequency</li>
                    </ul>
                </p>

                <hr id="ceiling-control" />
                <h4>Ceiling Control</h4>
                <p>
                    The ceiling control dictates the maximum peak value in decibels and can be used to conform to whatever standard is required for your production.
                    There is also a true peak control which conforms to BS.1770 standard.
                    Note that true peak may considerably reduce the volume of the track depending on its content. This prevents any analog system playing the signal above unity.
                </p>

                <hr id="loudness-release" />
                <h4>Loudness Release</h4>
                <p>
                    The loudness release control is in the settings screen. This a hyper parameter that affects the limiters in blackbox.
                    Reducing this control will create a more agressive modern limiting profile and increasing this will produce a smoother mix.
                </p>

                <hr id="stereo-cut-frequency" />
                <h4>Stereo Cut Frequency</h4>
                <p>
                    The stereo cut frequency control is in the settings screen. This a hyper parameter that affects mid side processing in blackbox.
                    In most cases it is a good idea to centralise the bass.
                    This frequency dictates the center of the role off frequency where you want the bass to become more mono.
                    In some cases you may wish to leave more stereo field on the bass so reducing this frequency down to its lowest setting will achieve this.
                </p>
            </section>

            <section id="metering">
                <h3>Metering</h3>

                <hr id="fft-metering" />
                <h4>FFT Analyser</h4>
                <img src="/content/blackbox/II/bbx-metering-fft.png" alt="Blackbox - FFT" />
                <p>
                    Blackbox now features a Fast Fourier Transform Analyser window in Real Time Mode. The FFT has has been augmented to show perceived sonic levels in each frequency band.
                </p>


                <hr id="loudness-metering" />
                <h4>Loudness Metering</h4>
                <img src="/content/blackbox/II/bbx-metering-loudness.png" alt="Blackbox - Loudness Meter" />
                <p>
                    Blackbox features professional broadcast metering.
                    You can now monitor levels in RMS, LUFS, Integrated Loudness and Dynamic Range.
                </p>

                <ul>
                    <li><strong>Root Mean Square (RMS)</strong> measures the average power of the signal in decibels at any moment in time.</li>
                    <li><strong>Loudness Unit (LUFS)</strong> is a short term loudness unit which shows the overal perceived loudness at different parts of a track.</li>
                    <li><strong>Integrated Loundness (INT)</strong> shows the percieved loudness across the entirety of the track.</li>
                    <li><strong>Dynamic Range (RNG)</strong> shows the percieved range in dynamics in decibels at any momemnt id time.</li>
                </ul>

            </section>

            <section id="exporting">
                <h3>Exporting</h3>

                <img src="/content/blackbox/II/blackbox-save-screen.png" alt="Blackbox - Save Screen" className="full-width" />

                <p>
                    Blackbox export options allow you to save to AIFF and WAV formats at whatever processed sample rate you pick.
                    If you choose to export to 16bit or 24bit you can addtionally add dithering signal which reduces aliasing / banding in the bit rate domain and thus smoothes out potential artifacts.
                    Dithering has several options, None, Rectangular, Triangular and POW-r2 which dictate what kind of signal is applied.
                    Once you have selected the appropriate options just click save and a window will appear prompting you for a file name.
                    The extension will be added automatically.
                    Once selected the save operation will start and in most cases will take under 10 seconds to complete.
                </p>
            </section>
          </div>
        </div>
      </div>
		</Layout>
	)
}
export default BlackboxWikiPage